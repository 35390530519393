export default function TranslationHero() {
	function bind_translation_hero( _selector ) {
		const selector = _selector ? _selector : $( 'body:not(.single-product) .emp-translation-hero-block form select' );

		if ( !$( 'body' ).hasClass( 'wp-admin' ) ) {
			selector.selectWoo();
		}

		selector.on( 'change', function ( e ) {
			e.preventDefault();
			$( this ).parent( 'form' ).trigger( 'submit' );
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=emp-translation-hero', function ( block ) {
			bind_translation_hero( $( block ).find( 'body:not(.single-product) .emp-translation-hero-block form select' ) );
		} );
	} else {
		bind_translation_hero();
	}
}
