export default function WhatWeDo() {
	function bind_what_we_do( _selector ) {
		$( '.emp-what-we-do-block svg>.svg-icon' ).on( 'click', function ( e ) {
			e.preventDefault();

			const target = $( this ).attr( 'data-target' );

			$( '.emp-what-we-do-block svg>.svg-icon' ).removeClass( 'active' );
			$( this ).addClass( 'active' );

			$( '.what-we-do-descs > div.active' ).removeClass( 'active' );
			$( '.what-we-do-descs > div:nth-child(' + target + ')' ).addClass( 'active' );
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=emp-what-we-do', function ( block ) {
			bind_what_we_do();
		} );
	} else {
		bind_what_we_do();
	}
}
