import Nav from './modules/nav';
import Social from './modules/social';
import Account from './modules/account';
import Products from './modules/products';
import Faqs from './modules/faqs';

import SyncPlacements from './modules/blocks/sync-placements';
import FeaturedWriters from './modules/blocks/featured-writers';
import FeaturedClients from './modules/blocks/featured-clients';
import WhatWeDo from './modules/blocks/what-we-do';
import Projects from './modules/projects';
import TranslationHero from './modules/blocks/translation-hero';
import Accordion from './modules/blocks/accordion';

jQuery( document ).ready( function ( $ ) {
	if ( !$( 'body' ).hasClass( 'wp-admin' ) ) {
		Account();
		Nav();
		Social();
		Products();
		Faqs();
	}

	SyncPlacements();
	FeaturedWriters();
	FeaturedClients();
	WhatWeDo();
	TranslationHero();
	Accordion();
	Projects();

	$( '.loop-cart svg' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).next( 'a' ).trigger( 'click' );
	} );

	// Trigger PDF error modal
	if ( $( '#pdf-error-modal' ).length > 0 ) {
		$.magnificPopup.open( {
			items: {
				src: '#pdf-error-modal',
				type: 'inline'
			}
		} );
	}
} );
