import 'owl.carousel';

export default function SyncPlacements() {
	function bind_sync_placements_carousel( _selector ) {
		const selector = _selector ? _selector : $( '.emp-sync-placements-block .owl-carousel' );
		const config = {
			items: 2,
			loop: true,
			dots: false,
			nav: true,
			navText: [
				'<i class="fal fa-arrow-circle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
				'<i class="fal fa-arrow-circle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
			],
			margin: 20,
			responsive: {
				0: {
					items: 1,
					margin: 20
				},
				768: {
					margin: 40
				},
				992: {
					margin: 70
				}
			}
		};

		selector.owlCarousel( config );

		selector.on( 'click', '.video-item>a', function ( e ) {
			e.preventDefault();
			const video_id = $( this ).attr( 'data-youtube-id' );
			const youtube_url = 'https://www.youtube.com/embed/' + video_id + '?autoplay=1';

			$( this ).parents( '.emp-sync-placements-block' ).find( 'iframe' ).attr( 'src', youtube_url );
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=emp-sync-placements', function ( block ) {
			bind_sync_placements_carousel( $( block ).find( '.emp-sync-placements-block .owl-carousel' ) );
		} );
	} else {
		bind_sync_placements_carousel();
	}
}
