import 'owl.carousel';

export default function FeaturedClients() {
	function bind_featured_clients_carousel( _selector ) {
		const selector = _selector ? _selector : $( '.emp-featured-clients-block .owl-carousel' );
		const config = {
			items: 5,
			loop: true,
			dots: false,
			nav: true,
			autoHeight: true,
			navText: [
				'<i class="fal fa-arrow-circle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
				'<i class="fal fa-arrow-circle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
			],
			margin: 15,
			responsive: {
				0: {
					items: 1,
				},
				768: {
					items: 3
				},
				992: {
					items: 5
				}
			}
		};

		selector.owlCarousel( config );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=emp-featured-clients', function ( block ) {
			bind_featured_clients_carousel( $( block ).find( '.emp-featured-clients-block .owl-carousel' ) );
		} );
	} else {
		bind_featured_clients_carousel();
	}
}
