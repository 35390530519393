export default function Social() {

	$( '.share-links>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		popupWindow( href, 'Share', window, 670, 378 );
	} );


	function popupWindow( url, title, win, w, h ) {
		const y = win.top.outerHeight / 2 + win.top.screenY - (
			h / 2
		);
		const x = win.top.outerWidth / 2 + win.top.screenX - (
			w / 2
		);
		return win.open( url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}` );
	}
}