import 'owl.carousel';

export default function Products() {
	$( '.product>.song-info>.play>a' ).on( 'click', function ( e ) {
		e.preventDefault();

		const i = $( this ).find( 'i' );
		if ( i.hasClass( 'fa-play-circle-o' ) ) {
			i.removeClass( 'fa-play-circle-o' ).addClass( 'fa-times-circle-o' );
		} else {
			i.removeClass( 'fa-times-circle-o' ).addClass( 'fa-play-circle-o' );
		}

		$( this ).parent( '.play' ).parent( '.song-info' ).parent( '.product' ).find( '.song-spotify' ).slideToggle();
	} );

	$( '.song-control a' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );

	$( '#emp-controlled-recordings .recordings-more>a:first-child' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#emp-controlled-recordings .products>li:nth-child(n+3)' ).css( 'display', 'flex' ).hide().fadeIn( 400, () => {
			$( this ).hide();
			$( '#emp-controlled-recordings .recordings-more>a:last-child' ).show();
		} );
	} );

	$( '#emp-controlled-recordings .recordings-more>a:last-child' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#emp-controlled-recordings .products>li:nth-child(n+3)' ).fadeOut( 400, () => {
			$( this ).hide();
			$( '#emp-controlled-recordings .recordings-more>a:first-child' ).show();
		} );
	} );

	$( '#artist-videos .owl-carousel, #artist-albums .owl-carousel' ).owlCarousel( {
		items: 3,
		margin: 30,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fa fa-arrow-circle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-arrow-circle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			768: {
				items: 2,
				margin: 30
			},
			1200: {
				items: 3,
				margin: 30
			}
		}
	} );

	$( '#artist-sync-videos .owl-carousel article>a, #artist-videos .owl-carousel article>a' ).on( 'click', function ( e ) {
		e.preventDefault();

		const video_player = $( '#video-player' );

		const yt_id = $( this ).attr( 'data-yt-id' );
		const title = $( this ).find( '.video-title' ).html();

		video_player.find( 'iframe' ).attr( 'src', 'https://www.youtube.com/embed/' + yt_id + '?autoplay=1' );
		video_player.find( '.video-title' ).html( title );
	} );

	$( '.emp-publishing-details-header>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.emp-publishing-details-header>a' ).toggleClass( 'active' );
		$( '#emp-publishing-details .emp-table-wrap' ).toggleClass( 'emp-controlled-on' );
		if ( $( '.emp-table-body-row:not(.is-controlled)' ).is( ':visible' ) ) {
			$( '.emp-table-body-row:not(.is-controlled)' ).fadeOut();
		} else {
			$( '.emp-table-body-row:not(.is-controlled)' ).css( 'display', 'flex' ).hide().fadeIn();
		}
	} );

	$( '.emp-section>header>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).find( 'i' ).toggleClass( 'fa-plus-circle' ).toggleClass( 'fa-minus-circle' );
		$( this ).parent().next( '.emp-section-desc' ).slideToggle();
	} );

	$( 'body.single-product .emp-translation-hero-block form select' ).on( 'change', function ( e ) {
		e.preventDefault();
		const href = $( this ).val();
		window.location.href = href;
	} );
}
