export default function Accordion() {
	function bind_accordion( _selector ) {
		const selector = _selector ? _selector : $( '.emp-accordion-block>.emp-accordion-header>a' );
		selector.on( 'click', function ( e ) {
			e.preventDefault();
			var self = $( this );
			$( this ).parent().next( '.emp-accordion-content' ).slideToggle( 400, function () {
				self.find( 'i' ).toggleClass( 'fa-plus-circle' ).toggleClass( 'fa-minus-circle' );
			} );
		} );

		$( '.emp-accordion-block .gfield_select' ).selectWoo();

	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=emp-accordion', function ( block ) {
			bind_accordion( $( block ).find( '.emp-accordion-block>.emp-accordion-header>a' ) );
		} );
	} else {
		bind_accordion();
	}
}
